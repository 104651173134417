import axios from 'axios'
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import store from '@/store'
import { BackendInstance, makeBackendInstance } from '@/types/auth'
import { gerRouterUrl, getErrorText } from '@/utils/http'

export const BackendMixin = Vue.extend({
  created: () => {},
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),

    isLoading(loadingName: string): boolean {
      return store.getters.isLoading(loadingName)
    },

    getFullBackendUrl(url: string): string {
      return store.getters.backendUrl + url
    },

    getFrontendSetting(setting: string): string {
      if (store.getters.frontendInstance) {
        return store.getters.frontendInstance.getFrontendSetting(setting)
      }
      return ''
    },

    getBackendsFromEmail(email: string): Promise<BackendInstance[]> {
      return new Promise(
        (resolve, reject) => {
          axios({
            url: gerRouterUrl('/api/backends/accounts/'),
            data: { email, },
            method: 'POST',
          }).then(async(resp: any) => {
            if (!resp.data.success) {
              reject(Error(resp.data.message))
            } else {
              const backends: BackendInstance[] = resp.data.backends.map(
                (jsonElt: any) => makeBackendInstance(jsonElt)
              ).filter(
                (elt: BackendInstance) => elt.url
              )
              resolve(backends)
            }
          }).catch(err => {
            reject(err)
          })
        }
      )
    },

    getErrorText(err: any): string {
      return getErrorText(err)
    },
  },
})
